import {Injectable} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Response} from '../models/response';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private checkTokenURL = 'https://read.bedify.io/api/get-token';
  private requestTokenURL = 'https://write.bedify.io/oauth/token';
  private saveTokenURL = 'https://write.bedify.io/api/save-token';

  httpOptions: any;
  token: string;
  private response: Response;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json')
    };
  }


  // check if valid token exists
  checkToken() {
    return this.http.post<Response>(this.checkTokenURL, {
      user_type: '1',
    }, {headers: this.httpOptions.headers})
      .pipe(tap(resp => {
        return resp;
      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  // Request for new token
  requestToken() {
    return this.http.post<Response>(this.requestTokenURL, {
      grant_type: 'client_credentials',
      client_id: '1',
      client_secret: 'ZkuWAJHOK3H6tEYcMfiqqJhBu9XJarlXt8mnx4KK',
    }, {headers: this.httpOptions.headers})
      .pipe(tap(resp => {
        this.token = resp.access_token;
        localStorage.setItem('portNum', this.token);
      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  // Save requested token
  saveToken(accessToken) {
    return this.http.post<Response>(this.saveTokenURL, {
      user_type: '1',
      token: accessToken
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }
}
