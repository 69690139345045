import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Response} from '../models/response';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  httpOptions: any;
  private loginUrl = 'https://write.bedify.io/api/customer/sign-in';
  private resetPwUrl = 'https://read.bedify.io/api/customer/reset-password';
  private forgetPasswordResetPwUrl = 'https://read.bedify.io/api/customer/forget-reset-password';
  private resetPwSubmitUrl = 'https://write.bedify.io/api/customer/reset-password';
  private sendOTPURL = 'https://write.bedify.io/api/customer/send-otp';
  private reSendOTPURL = 'https://write.bedify.io/api/customer/re-send-otp';
  private verifyOTPURL = 'https://write.bedify.io/api/customer/verify-otp';

  accessToken: string;
  hsh: string;


  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json')
    };
  }

  // get ip address
  public getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  // customer login
  loginUser(accessToken, cusEmail, cusPassword) {

    return this.http.post<Response>(this.loginUrl, {
      password: cusPassword,
      email: cusEmail,
      ip_address: localStorage.getItem('ipAddress'),
      user_type: '1',
      application_type: 0
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  // Initiate forgot password
  // Check if user exist on DB
  resetPassword(accessToken, cusEmail, userSlug, userToken) {

    return this.http.post<Response>(this.resetPwUrl, {
      email: cusEmail,
      user_type: '1',
      slug: userSlug,
      login_token: userToken
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  // New Password submit
  resetPasswordSubmit(accessToken, cusSlug, cusPassword, cusPasswordConfirm, userToken) {

    return this.http.post<Response>(this.resetPwSubmitUrl, {
      slug: cusSlug,
      password: cusPassword,
      password_confirmation: cusPasswordConfirm,
      login_token: userToken
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  sendOtp(accessToken, cusEmail, userSlug, userToken) {

    return this.http.post<Response>(this.sendOTPURL, {
      email: cusEmail,
      user_type: '1',
      slug: userSlug,
      login_token: userToken
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  reSendOtp(accessToken, email, verificationCode, userSlug, userToken) {
    return this.http.post<Response>(this.reSendOTPURL, {
      email: email,
      verification_code: verificationCode,
      user_type: 1,
      slug: userSlug,
      login_token: userToken
    }, { headers: { Authorization: 'Bearer ' + accessToken } })
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }


  reSendOtpForMobile(accessToken, contactNo, verificationCode, userSlug, userToken) {
    return this.http.post<Response>(this.reSendOTPURL, {
      contact_no: contactNo,
      verification_code: verificationCode,
      user_type: 1,
      slug: userSlug,
      login_token: userToken
    }, { headers: { Authorization: 'Bearer ' + accessToken } })
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  verifyOtp(accessToken, otp, verificationCode, userSlug, userToken) {
    return this.http.post<Response>(this.verifyOTPURL, {
      otp: otp,
      verification_code: verificationCode,
      slug: userSlug,
      login_token: userToken
    }, { headers: { Authorization: 'Bearer ' + accessToken } })
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  forgetPasswordResetPassword(accessToken, cusEmail) {

    return this.http.post<Response>(this.forgetPasswordResetPwUrl, {
      email: cusEmail,
      user_type: '1'
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

}
