import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {of, BehaviorSubject} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Response} from '../models/response';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  httpOptions: any;
  private registerURL = 'https://write.bedify.io/api/customer/register';
  private otpSubmitURL = 'https://write.bedify.io/api/customer/verify-otp';
  private saveCustomerURL = 'https://write.bedify.io/api/customer/save-customer';
  private scanVirusURL = 'https://write.bedify.io/api/file/scan-file';

  accessToken: string;
  hsh: string;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json')
    };
  }

  // Save user to backend db
  signUpUserStep1(accessToken, emailAdd, contactNo, newPassword, confirmNewPassword, tncChecked) {

    return this.http.post<Response>(this.registerURL, {
      contact_no: contactNo,
      email: emailAdd,
      password: newPassword,
      password_confirmation: confirmNewPassword,
      user_type: 1,
      tnc: tncChecked,
      application_type: 0
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  // Submit OTP
  signUpUserStep2(accessToken, OTP, verificationCode, userSlug, userToken) {

    return this.http.post<Response>(this.otpSubmitURL, {
      otp: OTP,
      verification_code: verificationCode,
      slug: userSlug,
      login_token: userToken
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  // Submit personal data along with customer slug
  signUpUserStep3(accessToken, customerSlug, firstName, lastName, cusAddress, nic, nicFIle, radioValue, backNic, userToken) {

    return this.http.post<Response>(this.saveCustomerURL, {
      slug: customerSlug,
      first_name: firstName,
      last_name: lastName,
      address: cusAddress,
      nic_copy_name: nicFIle,
      back_nic_copy_name: backNic,
      nic_driving_license: nic,
      user_type: 1,
      document_type: radioValue,
      login_token:userToken,
      credolab_permission_status: 0
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        // this.response = resp;
      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  //scan file before uploading to the s3
  scanForVirus(accessToken, fileToUpload) {

    return this.http.post<Response>(this.scanVirusURL, fileToUpload, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        console.error(resp);
        return resp;
      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }
}
