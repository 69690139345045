<app-navigation></app-navigation>
<div class="content-sec">
  <div class="container">

    <notifier-container></notifier-container>

    <!--  SIGN UP - STEP 01 SECTION  -->
    <div *ngIf="isStep1" class="card shadow-md-lg border-md-0 mb-3 mb-5">
      <div class="row no-gutters create-acc-gutter">
        <div class="col-md-6 d-none d-md-block d-lg-block">
          <div class="signup-bg">
            <img src="./assets/images/signup-bg.png" class="card-img" alt="login-background">
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-body text-center p-3 ml-md-0 mr-md-0 ml-lg-5 mr-lg-5 mt-0 mt-md-3">
            <img class="logform-logo mb-3 mt-2" src="./assets/images/logo-v.svg" width="75" alt="payd-logo">
            <h1 class="card-title ml-md-0 mr-md-0 mt-3 mb-4">JOIN US</h1>
            <h5 class="ml-md-0 mr-md-0 mb-5">Create Account</h5>

            <form class="ml-md-5 mr-md-5 mb-2" [formGroup]="signupForm">
              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-envelope"></i></span>
                  </div>
                  <input type="email" class="form-control" placeholder="Email" aria-label="Email" [(ngModel)]="email"
                         aria-describedby="basic-addon1" formControlName="email"
                         [ngClass]="{'is-invalid': signupForm.get('email').touched && signupForm.get('email').invalid}">
                  <div *ngIf="signupForm.get('email').touched && signupForm.get('email').invalid"  class="invalid-feedback text-right">
                    <div *ngIf="signupForm.get('email').errors.required">This field is required.</div>
                    <div *ngIf="signupForm.get('email').errors.pattern">Please enter a valid email address.</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon2"><i class="fas fa-phone" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Mobile Number" aria-label="Mobile" [(ngModel)]="phone"
                         aria-describedby="basic-addon2" formControlName="phone"
                         [ngClass]="{'is-invalid': signupForm.get('phone').touched && signupForm.get('phone').invalid}">
                  <div *ngIf="signupForm.get('phone').touched && signupForm.get('phone').invalid"  class="invalid-feedback text-right">
                    <div *ngIf="signupForm.get('phone').errors.required">This field is required.</div>
                    <div *ngIf="signupForm.get('phone').errors.pattern">Please enter a valid mobile number.</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon3"><i class="fas fa-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Password" aria-label="Password" [(ngModel)]="password"
                         aria-describedby="basic-addon3" formControlName="password"
                         [ngClass]="{'is-invalid': signupForm.get('password').touched && signupForm.get('password').invalid}">
                  <div *ngIf="signupForm.get('password').touched && signupForm.get('password').invalid" class="invalid-feedback text-right">
                    <div *ngIf="signupForm.get('password').errors.required">This field is required.</div>
                    <div *ngIf="signupForm.get('password').errors.pattern">Password must be minimum 8 characters, at least 1 uppercase, 1 lowercase, 1 number & 1 special character</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon4"><i class="fas fa-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Confirm Password" aria-label="ConfirmPassword" [(ngModel)]="confirmPassword"
                         aria-describedby="basic-addon4" formControlName="confirmPassword"
                         [ngClass]="{'is-invalid': signupForm.get('confirmPassword').touched && signupForm.get('confirmPassword').invalid}">
                  <div *ngIf="signupForm.get('confirmPassword').touched && signupForm.get('confirmPassword').invalid" class="invalid-feedback text-right">
                    <div *ngIf="signupForm.get('confirmPassword').errors.required">This field is required.</div>
                    <div *ngIf="signupForm.get('confirmPassword').errors.passwordValidity">Confirm Password does not match.</div>
                  </div>
                </div>
              </div>

              <div class="form-check mb-3">
                <div class="custom-control custom-checkbox">
                  <p class="card-text checkbox">
                    <input type="checkbox" class="custom-control-input my-error" id="customCheck1" formControlName="checkBox"
                           [(ngModel)]="tncChecked" (change)="checkTnC(tncChecked?'1':'0')" [ngClass]="{'is-invalid': signupForm.get('checkBox').touched && signupForm.get('checkBox').invalid}">
                    <label class="custom-control-label text-left" for="customCheck1">
                      <small class="text-muted">I agree to <a href="https://www.payd.retailbizz.io/terms-and-conditions" target="_blank"><strong>Terms & Conditions</strong></a> of Payd</small>
                    </label>
                  </p>
                  <div *ngIf="signupForm.get('checkBox').touched && signupForm.get('checkBox').invalid"  class="invalid-feedback text-right">
                    <div *ngIf="signupForm.get('checkBox').errors.required">This field is required.</div>
                    <div *ngIf="signupForm.get('checkBox').errors.pattern">This field is required.</div>
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-primary w-100 p-2 mb-2 mt-1" [disabled]="!signupForm.valid"
                      (click)="submitStep1($event.target)">SIGN UP</button>

              <p class="card-text">
                <small class="text-muted">Already have an account?</small>
                <small class="text-muted"><a routerLink="/login"><strong> Sign In </strong></a></small>
                <small class="text-muted">here</small>
              </p>

            </form>
          </div>
        </div>
      </div>
    </div>


    <!--  SIGN UP - STEP 02 SECTION  -->
    <div *ngIf="isStep2" class="card shadow-md-lg border-md-0 mb-3 ml-3 mr-3 mb-5">
      <div class="row no-gutters otp-gutter">
        <div class="col-md-6 d-none d-md-block d-lg-block">
          <div class="signup-bg">
            <img src="./assets/images/signup-bg.png" class="card-img" alt="login-background">
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-body text-center p-3 ml-md-0 mr-md-0 ml-lg-5 mr-lg-5 mt-0 mt-md-3">
            <img class="logform-logo mb-3 mt-2" src="./assets/images/logo-v.svg" width="75" alt="payd-logo">
            <h1 class="card-title ml-md-0 mr-md-0 mt-3 mb-4">JOIN US</h1>
            <h5 class="ml-md-0 mr-md-0 mb-5">Verify Mobile Number</h5>

            <form class="ml-md-5 mr-md-5 mb-2" [formGroup]="otpForm">
              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon5"><i class="fas fa-unlock-alt"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="OTP" aria-label="otp"
                         aria-describedby="basic-addon5" formControlName="otp" [(ngModel)]="otp"
                         [ngClass]="{'is-invalid': otpForm.get('otp').touched && otpForm.get('otp').invalid}">
                  <div *ngIf="otpForm.get('otp').touched && otpForm.get('otp').invalid"  class="invalid-feedback text-right">
                    <div *ngIf="otpForm.get('otp').errors.required">This field is required.</div>
                    <div *ngIf="otpForm.get('otp').errors.pattern">Please enter 6 digits</div>
                  </div>
                </div>
              </div>

              <p *ngIf="showResendOtp == true"  class="card-text forget-pwd text-left ml-2">
                <a [routerLink]="" (click)="reSendOtpForContactNumber()"><small class="text-muted">Re-send OTP</small></a></p>
              <button type="submit" class="btn btn-primary w-100 p-2 mt-1" [disabled]="!otpForm.valid"
                      (click)="submitStep2($event.target)">SUBMIT</button>
              <p class="card-text mt-3"><small><a routerLink="#" (click)="prevStep1($event.target)"><strong><i class="fas fa-angle-double-left"></i> BACK</strong></a></small></p>
            </form>
          </div>
        </div>
      </div>
    </div>

    <input type="text" id="cusEmail" value="{{email}}" class="d-none">
    <input type="text" id="cusPasword" value="{{password}}" class="d-none">

    <!--  SIGN UP - STEP 03 SECTION  -->
    <div *ngIf="isStep3" class="card shadow-md-lg border-md-0 mb-3 ml-3 mr-3 mb-5">
      <div class="row no-gutters info-gutter">
        <div class="col-md-6 d-none d-md-block d-lg-block">
          <div class="signup-bg">
            <img src="./assets/images/signup-bg.png" class="card-img" alt="login-background">
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-body text-center p-3 ml-md-0 mr-md-0 ml-lg-5 mr-lg-5 mt-0 mt-md-3">
            <img class="logform-logo mb-3 mt-2" src="./assets/images/logo-v.svg" width="75" alt="payd-logo">
            <h1 class="card-title ml-md-0 mr-md-0 mt-3 mb-4">JOIN US</h1>
            <h5 class="ml-md-0 mr-md-0 mb-5">Basic Info</h5>

            <form class="ml-md-5 mr-md-5 mb-2" [formGroup]="infoForm">
              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon6"><i class="fas fa-user"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="First Name" aria-label="First Name" [(ngModel)]="firstName"
                         aria-describedby="basic-addon6" formControlName="firstName"
                         [ngClass]="{'is-invalid': infoForm.get('firstName').touched && infoForm.get('firstName').invalid}">
                  <div *ngIf="infoForm.get('firstName').touched && infoForm.get('firstName').invalid"  class="invalid-feedback text-right">
                    <div *ngIf="infoForm.get('firstName').errors.required">This field is required.</div>
                    <div *ngIf="infoForm.get('firstName').errors.minlength">First Name must be at least 3 characters</div>
                    <div *ngIf="infoForm.get('firstName').errors.pattern">First Name must be a string</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon7"><i class="fas fa-user"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Last Name" aria-label="Last Name" [(ngModel)]="lastName"
                         aria-describedby="basic-addon7" formControlName="lastName"
                         [ngClass]="{'is-invalid': infoForm.get('lastName').touched && infoForm.get('lastName').invalid}">
                  <div *ngIf="infoForm.get('lastName').touched && infoForm.get('lastName').invalid"  class="invalid-feedback text-right">
                    <div *ngIf="infoForm.get('lastName').errors.required">This field is required.</div>
                    <div *ngIf="infoForm.get('lastName').errors.minlength">Last Name must be at least 3 characters</div>
                    <div *ngIf="infoForm.get('lastName').errors.pattern">Last Name must be a string</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon8"><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Address" aria-label="Address" [(ngModel)]="address"
                         aria-describedby="basic-addon8" formControlName="address"
                         [ngClass]="{'is-invalid': infoForm.get('address').touched && infoForm.get('address').invalid}">
                  <div *ngIf="infoForm.get('address').touched && infoForm.get('address').invalid"  class="invalid-feedback text-right">
                    <div *ngIf="infoForm.get('address').errors.required">This field is required.</div>
                    <div *ngIf="infoForm.get('address').errors.minlength">Address must be at least 3 characters</div>
                  </div>
                </div>
              </div>

              <!-- Radio option -->
              <div class="form-group radio-option">
                <div class="option-check mr-sm-3">
                  <label class="option-label" (click)="nicRadio()">
                    <input type="radio" class="option-input radio" value="1" formControlName="radioValue"
                           (click)="nicRadio()" [(ngModel)]="radioValue" checked>
                    NIC
                  </label>
                </div>
                <div class="option-check mr-sm-3">
                  <label class="option-label" (click)="drivingRadio()">
                    <input type="radio" class="option-input radio" value="3" (click)="drivingRadio()" formControlName="radioValue" [(ngModel)]="radioValue">
                    Driving License
                  </label>
                </div>
                <div class="option-check mr-sm-3">
                  <label class="option-label" (click)="passportRadio()">
                    <input type="radio" class="option-input radio" value="2" (click)="passportRadio()" formControlName="radioValue" [(ngModel)]="radioValue">
                    Passport
                  </label>
                </div>
              </div>

              <!-- NIC Block -->
              <div *ngIf="nicOption" class="nic-id">
                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon9"><i class="fas fa-address-card"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="NIC Number" aria-label="nic" [(ngModel)]="nic"
                           aria-describedby="basic-addon9" formControlName="nicNum"
                           [ngClass]="{'is-invalid': infoForm.get('nicNum').touched && infoForm.get('nicNum').invalid}">
                    <div *ngIf="infoForm.get('nicNum').touched && infoForm.get('nicNum').invalid"  class="invalid-feedback text-right">
                      <div *ngIf="infoForm.get('nicNum').errors.required">This field is required.</div>
                      <div *ngIf="infoForm.get('nicNum').errors.pattern">Enter a valid NIC</div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon10"><i class="fas fa-file-upload"></i></span>
                    </div>
                    <div class="custom-file">
                      <!--<input type="file" class="custom-file-input" id="customFile" formControlName="fileUpload" [(ngModel)]="nicFile">-->
                      <!--<input type="file" class="custom-file-input" id="file" formControlName="nicFrontUpload"  (change)="handleFileInput($event.target.files)">-->
                      <input type="file" class="custom-file-input" id="file" formControlName="nicFrontUpload"  (change)="handleFileInput($event)">
                      <label class="custom-file-label text-left" *ngIf="imageName">{{imageName}}</label>
                      <label class="custom-file-label text-left" *ngIf="!imageName">Upload NIC Front</label>
                    </div>
                    <div class="invalid-feedback text-right d-block">
                      <div>{{fileExtensionMessageNic1}}</div>
                      <div *ngIf="imageSize > 3">File size exceed the maximum file size.</div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon20"><i class="fas fa-file-upload"></i></span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="file1" formControlName="nicBackUpload"  (change)="handleFileInput1($event)">
                      <label class="custom-file-label text-left" *ngIf="imageName1">{{imageName1}}</label>
                      <label class="custom-file-label text-left" *ngIf="!imageName1">Upload NIC Back</label>
                    </div>
                    <div class="invalid-feedback text-right d-block">
                      <div>{{fileExtensionMessageNic2}}</div>
                      <div *ngIf="imageSize > 3">File size exceed the maximum file size.</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Driving License Block -->
              <div *ngIf="drivingLcOption" class="driving-license">
                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon30"><i class="fas fa-address-card"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Driving License Number" aria-label="nic" [(ngModel)]="drivingLc"
                           aria-describedby="basic-addon30" formControlName="drivingLc"
                           [ngClass]="{'is-invalid': infoForm.get('drivingLc').touched && infoForm.get('drivingLc').invalid}">
                    <div *ngIf="infoForm.get('drivingLc').touched && infoForm.get('drivingLc').invalid"  class="invalid-feedback text-right">
                      <div *ngIf="infoForm.get('drivingLc').errors.required">This field is required.</div>
                      <div *ngIf="infoForm.get('drivingLc').errors.pattern">Enter a valid Driving License</div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon31"><i class="fas fa-file-upload"></i></span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="file2" formControlName="drivingLcUpload"  (change)="handleFileInputDriving($event)">
                      <label class="custom-file-label text-left" *ngIf="imageNameDriving">{{imageNameDriving}}</label>
                      <label class="custom-file-label text-left" *ngIf="!imageNameDriving">Upload Driving License</label>
                    </div>
                    <div class="invalid-feedback text-right d-block">
                      <div>{{fileExtensionMessageDriving}}</div>
                      <div *ngIf="imageSize > 3">File size exceed the maximum file size.</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Passport Block -->
              <div *ngIf="passportOption" class="passport">
                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon40"><i class="fas fa-address-card"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Passport Number" aria-label="nic" [(ngModel)]="passport"
                           aria-describedby="basic-addon40" formControlName="passport"
                           [ngClass]="{'is-invalid': infoForm.get('passport').touched && infoForm.get('passport').invalid}">
                    <div *ngIf="infoForm.get('passport').touched && infoForm.get('passport').invalid"  class="invalid-feedback text-right">
                      <div *ngIf="infoForm.get('passport').errors.required">This field is required.</div>
                      <div *ngIf="infoForm.get('passport').errors.pattern">Enter a valid Passport</div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon41"><i class="fas fa-file-upload"></i></span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="file3" formControlName="passportUpload"  (change)="handleFileInputPassport($event)">
                      <label class="custom-file-label text-left" *ngIf="imageNamePassport">{{imageNamePassport}}</label>
                      <label class="custom-file-label text-left" *ngIf="!imageNamePassport">Upload Passport</label>
                    </div>
                    <div class="invalid-feedback text-right d-block">
                      <div>{{fileExtensionMessagePassport}}</div>
                      <div *ngIf="imageSize>3">File size exceed the maximum file size.</div>
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-primary w-100 p-2 mt-1" [disabled]="!infoForm.valid" (click)="submitStep3($event.target)">NEXT</button>
              <p class="card-text mt-3"><small><a routerLink="#" (click)="prevStep2($event.target)"><strong><i class="fas fa-angle-double-left"></i> BACK</strong></a></small></p>
            </form>
          </div>
        </div>
      </div>
    </div>


    <!--  SIGN UP - STEP 04 SECTION  -->
    <div *ngIf="isStep4" class="card shadow-md-lg border-md-0 mb-3 ml-3 mr-3 mb-5">
      <div class="row no-gutters add-card-gutter">
        <div class="col-md-6 d-none d-md-block d-lg-block">
          <div class="signup-bg">
            <img src="./assets/images/signup-bg.png" class="card-img" alt="login-background">
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-body text-center p-3 ml-md-0 mr-md-0 ml-lg-5 mr-lg-5 mt-0 mt-md-3">
            <img class="logform-logo mb-3 mt-2" src="./assets/images/logo-v.svg" width="75" alt="payd-logo">
            <h1 class="card-title ml-md-0 mr-md-0 mt-3 mb-4">JOIN US</h1>
            <h5 class="ml-md-0 mr-md-0 mb-5">Add Payment Method</h5>

            <form class="ml-md-5 mr-md-5 mb-2">
              <div class="form-group">
                <p class="card-text">You will be redirected to the payment gateway to add your card</p>
              </div>

              <button type="submit" class="btn btn-primary w-100 p-2 mt-1" (click)="submitStep4()">ADD CARD</button>

<!--              <button type="submit" class="btn btn-primary w-100 p-2 mt-1" (click)="payNow()">Pay Now</button>-->

              <p class="card-text mt-3">
<!--                <small class="float-left">-->
<!--                  <a routerLink="#" (click)="prevStep3($event.target)"><strong><i class="fas fa-angle-double-left"></i> BACK</strong></a>-->
<!--                </small>-->
                <small class="float-right">
                  <a routerLink="#" (click)="skipStep4()"><strong>SKIP <i class="fas fa-angle-double-right"></i></strong></a>
                </small>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>


    <!--  SIGN UP - STEP 05 SECTION  -->
    <div *ngIf="isStep5" class="card shadow-md-lg border-md-0 mb-3 ml-3 mr-3 mb-5">
      <div class="row no-gutters acc-success-gutter">
        <div class="col-md-6 d-none d-md-block d-lg-block">
          <div class="signup-bg">
            <img src="./assets/images/signup-bg.png" class="card-img" alt="login-background">
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-body text-center p-3 ml-md-0 mr-md-0 ml-lg-5 mr-lg-5 mt-0 mt-md-3">
            <img class="logform-logo mb-3 mt-2" src="./assets/images/logo-v.svg" width="75" alt="payd-logo">

            <form class="ml-md-5 mr-md-5 mt-5 mb-2">
              <div class="badge-msg row mr-sm-3 ml-sm-3 d-flex">
                <span class="badge d-block m-auto badge-pill badge-success"><i class="fas fa-check fa-2x"></i></span>
              </div>

              <h5 class="ml-md-0 mr-md-0 mb-1 mt-4">Account creation success</h5>
              <p class="card-text mt-2">Your account is submitted for approval. We will notify via email when your account is activated.</p>

              <button type="submit" class="btn btn-primary w-100 p-2 mt-1" routerLink="/login">SIGN IN</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<script>
  $('input[type="checkbox"]').on('change', function() {
    $('input[type="checkbox"]').not(this).prop('checked', false);
  });
</script>

