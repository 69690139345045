<app-navigation></app-navigation>
<div class="content-sec">
  <div class="container">

    <!--  PAYMENT ACKNOWLEDGEMENT  -->
    <div class="card shadow-lg border-md-0 mb-3 ml-3 mr-3 mb-5">
      <div class="row no-gutters acc-success-gutter">
        <div class="col-md-6 d-none d-md-block d-lg-block">
          <div class="signup-bg">
            <img src="./assets/images/signup-bg.png" class="card-img" alt="login-background">
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-body text-center p-3 ml-md-0 mr-md-0 ml-lg-5 mr-lg-5 mt-0 mt-md-3">
            <img class="logform-logo mb-3 mt-2" src="./assets/images/logo-v.svg" width="75" alt="payd-logo">

            <form class="ml-md-5 mr-md-5 mt-5 mb-2">
              <div class="badge-msg row mr-sm-3 ml-sm-3 d-flex">
                <span class="badge d-block m-auto">
                  <i class="fas fa-exclamation-circle fa-3x text-danger"></i>
                </span>
              </div>

              <h5 class="ml-md-0 mr-md-0 mb-1 mt-4">Add card failed</h5>
              <p class="card-text mt-4">Either your card is associated with another account or something went wrong.
                Please try again.</p>
              <p class="card-text mt-2">Your account is submitted for approval. We will notify via email when your
                account is activated.</p>

              <button type="submit" class="btn btn-primary w-100 p-2 mt-1" routerLink="/login">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
