import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {SignUpService} from '../services/sign-up.service';
import {TokenService} from '../services/token.service';
import {CardService} from '../services/card.service';
import {NotifierService} from 'angular-notifier';
import {S3bucketserviceService} from '../services/s3bucketservice.service';
import {LoginService} from '../services/login.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  otpForm: FormGroup;
  infoForm: FormGroup;

  email: string;
  password: string;
  confirmPassword: string;
  tncChecked = 0;
  phone: number;
  otp: number;
  customerSlug: string;
  firstName: string;
  lastName: string;
  address: string;
  nic: string;
  orderID: string;
  drLicense: string;
  passport: string;
  radioValue: string;
  drivingLc: string;

  fileToUpload: File = null;
  backFileToUpload: File = null;
  imageName: string;
  imageName1: string;
  imageSize: number;
  fileToUploadDriving: File = null;
  fileToUploadPassport: File = null;
  imageNameDriving: string;
  imageNamePassport: string;
  commonImageName: string;
  backName: string;
  phoneNumber: string;
  verification: string;

  // show and hide steps
  isStep1 = true;
  isStep2 = false;
  isStep3 = false;
  isStep4 = false;
  isStep5 = false;
  loadingBtn;

  // radio button click option
  nicOption = true;
  drivingLcOption = false;
  passportOption = false;

  // Enable & Disable Input Fields
  nicNumDisabled = true;
  nicFrontDisabled = true;
  nicBackDisabled = true;

  drivingLcDisabled = true;
  drivingImgDisabled = true;

  passportNumDisabled = true;
  passportImgDisabled = true;

  fileDataNicFrontScan: any = '';
  fileDataNicBackScan: any = '';
  fileDataDrivingScan: any = '';
  fileDataPassportScan: any = '';

  // file upload valid types
  allowedExtensions = ["image/png", "image/jpg", "image/jpeg", "image/pdf"];
  fileExtensionMessageNic1: any;
  fileExtensionMessageNic2: any;
  fileExtensionMessageDriving: any;
  fileExtensionMessagePassport: any;
  fileExtensionError: boolean = false;

  verificationCode: string;
  userSlug: string;
  userToken: string;
  accessToken: string;
  resendCount: number = 0;
  count:number;
  showResendOtp: boolean = false;
  userToken1: string;

  private tokenizeCardURL = 'http://payment.retailbizz.io/api/ipg-redirect';

  /**
   * Notifier service
   */
  private notifier: NotifierService;
  displayName: string;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private signUpService: SignUpService,
              private tokenService: TokenService, notifier: NotifierService,
              private S3bucketservice: S3bucketserviceService, private cardService: CardService,
              private loginService: LoginService) {
    this.notifier = notifier;
  }

  ngOnInit() {
    // Sign up - Step01 Create Account Validation
    this.signupForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$')]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^(07)?(7)?[0-9]{8}$')]),
      password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'), this.passwordCheck]),
      confirmPassword: new FormControl('', [Validators.required, this.passwordCheck]),
      checkBox: new FormControl('', [Validators.required, Validators.pattern('true')])
    });
    this.signupForm.controls.password.valueChanges.subscribe(
      x => this.signupForm.controls.confirmPassword.updateValueAndValidity()
    );

    // Sign up - Step02 OTP Validation
    this.otpForm = new FormGroup({
      otp: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')])
    });

    // Sign up - Step03 Basic Info Validation
    // @ts-ignore
    this.infoForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
      address: new FormControl('', [Validators.required, Validators.minLength(3)]),
      // nic: new FormControl('', [Validators.required, Validators.pattern('^([0-9]{12})|((((?=.*?[V])|(?=.*?[v]))(?=.*?[0-9]{9})).{10})|(((?=.*?[A-Z])|(?=.*?[a-z]))(?=.*?[0-9])).{8}$')]),
      // nicPass: new FormControl('', [Validators.required, Validators.pattern('^([0-9]{12})|((((?=.*?[V])|(?=.*?[v]))(?=.*?[0-9]{9})).{10})|(((?=.*?[A-Z])|(?=.*?[a-z]))(?=.*?[0-9])).{8}$')]),
      // fileUpload: new FormControl('', Validators.required)
      radioValue: new FormControl(),
      nicNum: new FormControl('', [Validators.required, Validators.pattern('^([0-9]{12})|((((?=.*?[V])|(?=.*?[v]))(?=.*?[0-9]{9})).{10})|(((?=.*?[A-Z])|(?=.*?[a-z]))(?=.*?[0-9])).{8}$')]),
      nicFrontUpload: new FormControl('', Validators.required),
      nicBackUpload: new FormControl('', Validators.required),

      drivingLc: new FormControl('', [Validators.required, Validators.pattern('^([0-9]{12})|((((?=.*?[V])|(?=.*?[v]))(?=.*?[0-9]{9})).{10})|(((?=.*?[A-Z])|(?=.*?[a-z]))(?=.*?[0-9])).{8}$')]),
      drivingLcUpload: new FormControl('', Validators.required),

      passport: new FormControl('', [Validators.required, Validators.pattern('^([0-9]{12})|((((?=.*?[V])|(?=.*?[v]))(?=.*?[0-9]{9})).{10})|(((?=.*?[A-Z])|(?=.*?[a-z]))(?=.*?[0-9])).{8}$')]),
      passportUpload: new FormControl('', Validators.required)
    });

    this.displayName = localStorage.getItem('displayName');
  }

  // radio NIC btn click
  nicRadio() {
    this.nicOption = true;
    this.drivingLcOption = false;
    this.passportOption = false;

    this.infoForm.controls['nicNum'].enable();
    this.infoForm.controls['nicFrontUpload'].enable();
    this.infoForm.controls['nicBackUpload'].enable();

    this.infoForm.controls['drivingLc'].disable();
    this.infoForm.controls['drivingLcUpload'].disable();
    this.infoForm.controls['passport'].disable();
    this.infoForm.controls['passportUpload'].disable();

    this.infoForm.patchValue({
      drivingLcUpload: null,
      passportUpload: null
    });
  }

  // radio Driving License btn click
  drivingRadio() {
    this.nicOption = false;
    this.drivingLcOption = true;
    this.passportOption = false;

    this.infoForm.controls['drivingLc'].enable();
    this.infoForm.controls['drivingLcUpload'].enable();

    this.infoForm.controls['nicNum'].disable();
    this.infoForm.controls['nicFrontUpload'].disable();
    this.infoForm.controls['nicBackUpload'].disable();
    this.infoForm.controls['passport'].disable();
    this.infoForm.controls['passportUpload'].disable();

    this.infoForm.patchValue({
      nicFrontUpload: null,
      nicBackUpload: null,
      passportUpload: null
    });
  }

  // radio Passport btn click
  passportRadio() {
    this.nicOption = false;
    this.drivingLcOption = false;
    this.passportOption = true;

    this.infoForm.controls['passport'].enable();
    this.infoForm.controls['passportUpload'].enable();

    this.infoForm.controls['nicNum'].disable();
    this.infoForm.controls['nicFrontUpload'].disable();
    this.infoForm.controls['nicBackUpload'].disable();
    this.infoForm.controls['drivingLc'].disable();
    this.infoForm.controls['drivingLcUpload'].disable();

    this.infoForm.patchValue({
      nicFrontUpload: null,
      nicBackUpload: null,
      drivingLcUpload: null
    });
  }

  checkTnC(value) {
    console.log(value);
  }

  // Compare password and confirm password
  passwordCheck(control) {
    if (control.value != null) {
      const confirmPassword = control.value;
      const pass = control.root.get('password');
      if (pass) {
        const password = pass.value;
        if (confirmPassword !== '' && password !== '') {
          if (confirmPassword !== password) {
            return {
              passwordValidity: true
            };
          } else {
            return null;
          }
        }
      }
    }
  }

  // on step 1 sign up button click
  submitStep1(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              // call API with new token after saved
              this.signUpUserStep1(this.accessToken, this.phone);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });
        }).catch((error) => {
          this.notifier.notify('error', JSON.stringify(error));
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.signUpUserStep1(this.accessToken, this.phone);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // Save customer in our db as an unverified customer
  signUpUserStep1(accessToken, phone) {
    if (phone.charAt(0) == '0'){
      this.phoneNumber = phone.substring(1);
    } else {
      this.phoneNumber = phone;
    }

    this.signUpService.signUpUserStep1(accessToken, this.email, this.phoneNumber,
      this.password, this.confirmPassword, this.tncChecked).subscribe(Response => {
      if (Response.successCode) {
        this.notifier.notify('success', Response.message);
        this.verificationCode = Response.data;
        this.userSlug = Response.data['user_slug'];
        this.userToken = Response.data['user_token'];
        localStorage.setItem('resentMobile', String(this.phone));
        localStorage.setItem('phoneVerification', this.verificationCode)
        setTimeout(() => {
          this.isStep1 = false;
          this.isStep2 = true;
          this.isStep3 = false;
          this.isStep4 = false;
          this.isStep5 = false;
        }, 1000);
        setTimeout(() => {
          this.showResendOtp = true;
        }, 180000)
      } else {

        // validation error
        if (Response.errorCode === '1000') {
          if (Response.data['email']) {
            this.notifier.notify('error', Response.data['email'][0]);
          }
          if (Response.data['contact_no']) {
            this.notifier.notify('error', Response.data['contact_no'][0]);
          }
          if (Response.data['password']) {
            this.notifier.notify('error', Response.data['password'][0]);
          }
          if (Response.data['password_confirmation']) {
            this.notifier.notify('error', Response.data['password_confirmation'][0]);
          }
          if (Response.data['tnc']) {
            this.notifier.notify('error', Response.data['tnc'][0]);
          }
        } else {
          this.notifier.notify('error', Response.message);
        }
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'SIGN UP';
          this.loadingBtn.disabled = false;
        }, 500);
      }
    });
  }

  // on step 2 click
  submitStep2(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              // Call API with new token
              this.signUpUserStep2(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.signUpUserStep2(this.accessToken);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // submit verification code
  signUpUserStep2(accessToken) {

    this.signUpService.signUpUserStep2(accessToken, this.otp, this.verificationCode, this.userSlug, this.userToken).subscribe(Response => {
      if (Response.successCode) {
        this.notifier.notify('success', Response.message);
        this.customerSlug = Response.data;
        setTimeout(() => {
          this.isStep1 = false;
          this.isStep2 = false;
          this.isStep3 = true;
          this.isStep4 = false;
          this.isStep5 = false;
        }, 1000);
      } else {
        if(Response.errorCode == '1001') {
          this.isStep1 = true;
          this.isStep2 = false;
          this.isStep3 = false;
          this.isStep4 = false;
        }
        this.notifier.notify('error', Response.message);
        // console.log('test-error');
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'SUBMIT';
          this.loadingBtn.disabled = false;
        }, 500);
      }
    });
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.
    }
  }

  handleFileInput(fileEvent: any) {
    const fileList: FileList = fileEvent.target.files;
    //check whether file is selected or not
    if (fileList.length > 0) {
      const nicFile1 = fileEvent.target.files[0];
      if(this.allowedExtensions.includes(nicFile1.type)){
        this.fileExtensionError = false;
        this.fileExtensionMessageNic1 = ""
      } else {
        this.fileExtensionMessageNic1 = "Only png, jpg and jpeg format files are allowed."
        this.fileExtensionError = true;
      }

      if(this.fileExtensionMessageNic1 === "") {
        this.fileToUpload = nicFile1;
        this.imageName = this.fileToUpload.name;
        this.imageSize = ((this.fileToUpload.size) / (1024 * 1024));
      }

      let formDataForNicFront = new FormData();
      formDataForNicFront.append('file', nicFile1, nicFile1.name);
      formDataForNicFront.append('name', nicFile1.name);
      formDataForNicFront.append('tz',new Date().toISOString())
      this.fileDataNicFrontScan = formDataForNicFront;
    }

  }

  handleFileInput1(fileEvent: any) {
    const fileList2: FileList = fileEvent.target.files;
    if (fileList2.length > 0) {
      const nicFile2 = fileEvent.target.files[0];
      if(this.allowedExtensions.includes(nicFile2.type)){
        this.fileExtensionError = false;
        this.fileExtensionMessageNic2 = ""
      } else {
        this.fileExtensionMessageNic2 = "Only png, jpg and jpeg format files are allowed."
        this.fileExtensionError = true;
      }

      if(this.fileExtensionMessageNic2 === "") {
        this.backFileToUpload = nicFile2;
        this.imageName1 = this.backFileToUpload.name;
        this.imageSize = ((this.backFileToUpload.size) / (1024 * 1024));
      }

      let formDataForNicBack = new FormData();
      formDataForNicBack.append('file', nicFile2, nicFile2.name);
      formDataForNicBack.append('name', nicFile2.name);
      formDataForNicBack.append('tz',new Date().toISOString())
      this.fileDataNicBackScan = formDataForNicBack;
    }

  }

  handleFileInputDriving(fileEvent: any) {
    const fileList3: FileList = fileEvent.target.files;
    if (fileList3.length > 0) {
      const drivingFile = fileEvent.target.files[0];
      if(this.allowedExtensions.includes(drivingFile.type)){
        this.fileExtensionError = false;
        this.fileExtensionMessageDriving = ""
      } else {
        this.fileExtensionMessageDriving = "Only png, jpg and jpeg format files are allowed."
        this.fileExtensionError = true;
      }

      if(this.fileExtensionMessageDriving === "") {
        this.fileToUploadDriving = drivingFile;
        this.imageNameDriving = this.fileToUploadDriving.name;
        this.imageSize = ((this.fileToUploadDriving.size) / (1024 * 1024));
      }

      let formDataForDriving = new FormData();
      formDataForDriving.append('file', drivingFile, drivingFile.name);
      formDataForDriving.append('name', drivingFile.name);
      formDataForDriving.append('tz',new Date().toISOString())
      this.fileDataDrivingScan = formDataForDriving;
    }

  }

  handleFileInputPassport(fileEvent: any) {
    const fileList4: FileList = fileEvent.target.files;
    if (fileList4.length > 0) {
      const passportFile = fileEvent.target.files[0];
      if(this.allowedExtensions.includes(passportFile.type)){
        this.fileExtensionError = false;
        this.fileExtensionMessagePassport = ""
      } else {
        this.fileExtensionMessagePassport = "Only png, jpg and jpeg format files are allowed."
        this.fileExtensionError = true;
      }

      if(this.fileExtensionMessagePassport === "") {
        this.fileToUploadPassport = passportFile;
        this.imageNamePassport = this.fileToUploadPassport.name;
        this.imageSize = ((this.fileToUploadPassport.size) / (1024 * 1024));
      }

      let formDataForPassport = new FormData();
      formDataForPassport.append('file', passportFile, passportFile.name);
      formDataForPassport.append('name', passportFile.name);
      formDataForPassport.append('tz',new Date().toISOString())
      this.fileDataPassportScan = formDataForPassport;
    }

  }

  // on step 3 click
  submitStep3(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.signUpUserStep3(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.signUpUserStep3(this.accessToken);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // submit additional customer data
  signUpUserStep3(accessToken) {
    if(this.radioValue == "1") {

      this.S3bucketservice.uploadFile(this.fileToUpload);
      this.S3bucketservice.uploadFile(this.backFileToUpload);
      this.commonImageName = this.fileToUpload.name;
      this.backName = this.backFileToUpload.name;
      this.nic = this.nic;

      // //COMMENT ABOVE CODE & UNCOMMENT BELOW CODE TO START VIRUS SCANNING PART - THIS IS WORKING ONLY ON UAT**
      // //'composer require sunspikes/clamav-validator' - INSTALL THIS TO WRITE API
      //
      // //virus scanner for nic front image upload
      // this.signUpService.scanForVirus(accessToken, this.fileDataNicFrontScan).subscribe(Response => {
      //   if (Response.successCode) {
      //     this.S3bucketservice.uploadFile(this.fileToUpload);
      //
      //     //virus scanner for nic back image upload
      //     this.signUpService.scanForVirus(accessToken, this.fileDataNicBackScan).subscribe(Response => {
      //       if (Response.successCode) {
      //         this.S3bucketservice.uploadFile(this.backFileToUpload);
      //         this.commonImageName = this.fileToUpload.name;
      //         this.backName = this.backFileToUpload.name;
      //         this.nic = this.nic;
      //       } else {
      //         this.notifier.notify('success', Response.message);
      //         console.log('Response Error');
      //         setTimeout(() => {
      //           this.loadingBtn.innerHTML = 'NEXT';
      //           this.loadingBtn.disabled = false;
      //         }, 500);
      //       }
      //     });
      //   } else {
      //     this.notifier.notify('success', Response.message);
      //     console.log('Response Error');
      //     setTimeout(() => {
      //       this.loadingBtn.innerHTML = 'NEXT';
      //       this.loadingBtn.disabled = false;
      //     }, 500);
      //   }
      // });
    } else if(this.radioValue == "3") {

      this.S3bucketservice.uploadFile(this.fileToUploadDriving);
      this.commonImageName = this.fileToUploadDriving.name;
      this.backName = null;
      this.nic = this.drivingLc;
      //COMMENT ABOVE CODE & UNCOMMENT BELOW CODE TO START VIRUS SCANNING PART - THIS IS WORKING ONLY ON UAT**
      // //virus scanner for driving licence image upload
      // this.signUpService.scanForVirus(accessToken, this.fileDataDrivingScan).subscribe(Response => {
      //   if (Response.successCode) {
      //     this.S3bucketservice.uploadFile(this.fileToUploadDriving);
      //     this.commonImageName = this.fileToUploadDriving.name;
      //     this.backName = null;
      //     this.nic = this.drivingLc;
      //   } else {
      //     this.notifier.notify('success', Response.message);
      //     console.log('Response Error');
      //     setTimeout(() => {
      //       this.loadingBtn.innerHTML = 'NEXT';
      //       this.loadingBtn.disabled = false;
      //     }, 500);
      //   }
      // });

    } else if(this.radioValue == "2"){

      this.S3bucketservice.uploadFile(this.fileToUploadPassport);
      this.commonImageName = this.fileToUploadPassport.name;
      this.backName = null;
      this.nic = this.passport;
      //COMMENT ABOVE CODE & UNCOMMENT BELOW CODE TO START VIRUS SCANNING PART - THIS IS WORKING ONLY ON UAT**
      // //virus scanner for passport image upload
      // this.signUpService.scanForVirus(accessToken, this.fileDataPassportScan).subscribe(Response => {
      //   if (Response.successCode) {
      //     this.S3bucketservice.uploadFile(this.fileToUploadPassport);
      //     this.commonImageName = this.fileToUploadPassport.name;
      //     this.backName = null;
      //     this.nic = this.passport;
      //   } else {
      //     this.notifier.notify('success', Response.message);
      //     console.log('Response Error');
      //     setTimeout(() => {
      //       this.loadingBtn.innerHTML = 'NEXT';
      //       this.loadingBtn.disabled = false;
      //     }, 500);
      //   }
      // });
    }
    // localStorage.setItem('displayName', this.firstName);
    this.signUpService.signUpUserStep3(accessToken, this.customerSlug, this.firstName, this.lastName,
      this.address, this.nic, this.commonImageName,this.radioValue, this.backName,this.userToken).subscribe(Response => {
      if (Response.successCode) {
        this.userToken1 = Response.data['user_token'];
        this.notifier.notify('success', Response.message);
        setTimeout(() => {
          this.isStep1 = false;
          this.isStep2 = false;
          this.isStep3 = false;
          this.isStep4 = true;
          this.isStep5 = false;
        }, 1000);
        // this.displayName = localStorage.getItem('displayName');
        // $('.main').addClass('d-none');
        // $('.profile').removeClass('d-none');
        // $('.container .nav-form').removeClass('d-md-block d-lg-block');
        // $('.container .nav-form').addClass('d-md-flex d-lg-flex');
      } else {
        this.notifier.notify('error', Response.message);
        console.log('Response Error');
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'NEXT';
          this.loadingBtn.disabled = false;
        }, 500);
      }
    });
  }

  // on step 4 click
  submitStep4() {
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.signUpUserStep4(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.signUpUserStep4(this.accessToken);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // on step 4 submit generate order ID and send tokenization request
  signUpUserStep4(accessToken) {

    const promise = this.cardService.generateOrder(accessToken, this.customerSlug, this.userToken1).toPromise();
    promise.then((Response) => {
      if (Response.successCode) {
        this.orderID = Response.data;
        // Add additional param as 2 to redirect to different page on sign up and profile
        // 1 - sign up
        // 2 - profile
        window.location.href = this.tokenizeCardURL + '/' + this.email + '/' + this.orderID + '/1';
      } else {
        this.notifier.notify('error', Response.message);
      }
    }).catch((error) => {
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // on step 2 previous
  prevStep1(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;
    setTimeout(() => {
      this.isStep1 = true;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = false;
    }, 1000);
  }

  // on step 3 previous
  prevStep2(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;
    setTimeout(() => {
      this.isStep1 = true;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = false;
    }, 1000);
  }

  // on step 4 previous
  prevStep3(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;
    setTimeout(() => {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = true;
      this.isStep4 = false;
      this.isStep5 = false;
    }, 1000);
  }

  // on step 4 skip
  skipStep4() {
    this.isStep1 = false;
    this.isStep2 = false;
    this.isStep3 = false;
    this.isStep4 = false;
    this.isStep5 = true;
  }

  // Resent OTP
  reSendOtpForContactNumber() {
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.resentOtpForContactNumberWithAccessToken(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });


      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.resentOtpForContactNumberWithAccessToken(this.accessToken);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }


  // Initiate forgot password OTP
  resentOtpForContactNumberWithAccessToken(accessToken) {
    this.phoneNumber = localStorage.getItem('resentMobile');
    this.verification = localStorage.getItem('phoneVerification');
    console.log(this.verification);
    this.resendCount = this.resendCount +1 ;
    localStorage.setItem('clickCount', String(this.resendCount));
    this.count = Number(localStorage.getItem('clickCount'));
    if (this.count > 2) {
      this.resendCount= 0;
      this.notifier.notify('error', 'Too many resend attempts! Please try again');
      location.reload();
      localStorage.removeItem('clickCount');
      localStorage.removeItem('phoneVerification');
      localStorage.removeItem('resentMobile');
    } else {
      this.loginService.reSendOtpForMobile(accessToken, this.phoneNumber, this.verification, this.userSlug, this.userToken).subscribe(Response => {
        if (Response.successCode) {
          setTimeout(() => {
            this.notifier.notify('success', 'Resent OTP');
          }, 1000);
        } else {
          this.notifier.notify('error', Response.message);
          // console.log('Response Error');
          setTimeout(() => {
            this.loadingBtn.innerHTML = 'SUBMIT';
            this.loadingBtn.disabled = false;
          }, 500);
        }
      });
    }

  }

}
