import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-save-card-success',
  templateUrl: './save-card-success.component.html',
  styleUrls: ['./save-card-success.component.scss']
})
export class SaveCardSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if (localStorage.getItem('displayName') != null) {
      $('.main').addClass('d-none');
      $('.profile').removeClass('d-none');
    }
  }

}
