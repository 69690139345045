import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {TokenService} from '../services/token.service';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../services/login.service';
import set = Reflect.set;

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  forgetPwdForm: FormGroup;
  resetForm: FormGroup;

  slug: string;
  email: string;
  userToken:string;
  otp: string;
  newPassword: string;
  confirmNewPassword: string;
  verification: string;

  // show and hide steps
  isStep1 = true;
  isStep2 = false;
  isStep3 = false;
  loadingBtn;
  showResendOtp: boolean = false;

  accessToken: string;
  /**
   * Notifier service
   */
  private notifier: NotifierService;

  constructor(notifier: NotifierService, private http: HttpClient, private loginService: LoginService,
              private tokenService: TokenService) {
    this.notifier = notifier;
  }

  ngOnInit() {
    this.forgetPwdForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$')])
    });

    this.resetForm = new FormGroup({
      otp: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')]),
      newPassword: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'), this.passwordCheck]),
      confirmPassword: new FormControl('', [Validators.required, this.passwordCheck])
    });
    this.resetForm.controls.newPassword.valueChanges.subscribe(
      x => this.resetForm.controls.confirmPassword.updateValueAndValidity()
    );
  }

  // Password matching check
  passwordCheck(control) {
    if (control.value != null) {
      const confirmPassword = control.value;
      const pass = control.root.get('newPassword');
      if (pass) {
        const password = pass.value;
        if (confirmPassword !== '' && password !== '') {
          if (confirmPassword !== password) {
            return {
              passwordValidity: true
            };
          } else {
            return null;
          }
        }
      }
    }
  }

  // On forgot password email submit
  submitStep1(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...'
    this.loadingBtn.disabled = true;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.resetPassword(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });


      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.resetPassword(this.accessToken);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // Initiate forgot password OTP
  resetPassword(accessToken) {
    this.loginService.forgetPasswordResetPassword(accessToken, this.email).subscribe(Response => {
      if (Response.successCode) {
        this.slug = Response.data['slug'];
        this.userToken = Response.data['token'];
        this.loginService.sendOtp(accessToken, this.email, this.slug, this.userToken).subscribe(Response => {
          if (Response.successCode) {
            setTimeout(() => {
              this.isStep1 = false;
              this.isStep2 = true;
              this.isStep3 = false;
              localStorage.setItem('resentEmail', this.email);
              localStorage.setItem('resentVerification', Response.data);
              this.notifier.notify('success', Response.message);

            }, 1000);
            setTimeout(() => {
              this.showResendOtp = true;
            }, 180000)
          } else if(Response.errorCode == '1004'){
            setTimeout(() => {
              location.href = '/login';
            }, 500);
            this.notifier.notify('error', Response.message);
          } else {
            this.notifier.notify('error', Response.message);
            // console.log('Response Error');
            setTimeout(() => {
              this.loadingBtn.innerHTML = 'SUBMIT';
              this.loadingBtn.disabled = false;
            }, 500);
          }
        })
      } else {
        this.notifier.notify('error', Response.message);
        // console.log('Response Error');
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'SUBMIT';
          this.loadingBtn.disabled = false;
        }, 500);
      }
    });
  }


  // On reset password OTP and new password submit
  submitStep2(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...'
    this.loadingBtn.disabled = true;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;
          this.resetPasswordSubmit(this.accessToken);
        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.resetPasswordSubmit(this.accessToken);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // Reset password submit with OTP
  resetPasswordSubmit(accessToken) {
    this.verification = localStorage.getItem('resentVerification');
    this.loginService.verifyOtp(accessToken, this.otp, this.verification,this.slug, this.userToken).subscribe(Response => {
      if (Response.successCode) {
        this.loginService.resetPasswordSubmit(accessToken, Response.data, this.newPassword, this.confirmNewPassword, this.userToken).subscribe(Response => {
          if (Response.successCode) {
            this.notifier.notify('success', Response.message);
            this.isStep1 = false;
            this.isStep2 = false;
            this.isStep3 = true;
          } else if(Response.errorCode == '1004'){
            setTimeout(() => {
              location.href = '/login';
            }, 500);
            this.notifier.notify('error', Response.message);
          } else {
            this.notifier.notify('error', Response.message);
            setTimeout(() => {
              this.loadingBtn.innerHTML = 'RESET PASSWORD';
              this.loadingBtn.disabled = false;
            }, 500);
          }
        });
      } else {
        this.notifier.notify('error', Response.message);
        // console.log('Response Error');
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'RESET PASSWORD';
          this.loadingBtn.disabled = false;
        }, 500);
      }

    });
  }

  resetBack(element) {
    // Loading Section
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;
    setTimeout(() => {
      this.isStep1 = true;
      this.isStep2 = false;
      this.isStep3 = false;
    }, 1000);
  }

  // Resent OTP
  reSendOtp() {
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.resentOtpWithAccessToken(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });


      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.resentOtpWithAccessToken(this.accessToken);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // Initiate forgot password OTP
  resentOtpWithAccessToken(accessToken) {
    this.email = localStorage.getItem('resentEmail');
    this.verification = localStorage.getItem('resentVerification');
    this.loginService.reSendOtp(accessToken, this.email, this.verification,this.slug,this.userToken).subscribe(Response => {
      if (Response.successCode) {
        setTimeout(() => {
          this.isStep1 = false;
          this.isStep2 = true;
          this.isStep3 = false;
          this.notifier.notify('success', 'Resent OTP');
        }, 1000);
      } else if(Response.errorCode == '1004'){
        setTimeout(() => {
          location.href = '/login';
        }, 500);
        this.notifier.notify('error', Response.message);
      } else {
        this.notifier.notify('error', Response.message);
        // console.log('Response Error');
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'SUBMIT';
          this.loadingBtn.disabled = false;
        }, 500);
      }
    });
  }

}
