import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Response} from '../models/response';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  private generateOrderURL = 'https://write.bedify.io/api/card/save-ipg-order';
  private payNowURL = 'https://write.bedify.io/api/card/pay-now';
  private removeCardURL = 'https://write.bedify.io/api/card/remove-card';
  private updateDefaultCardURL = 'https://write.bedify.io/api/card/update-default';

  constructor(private http: HttpClient) {
  }

  // Generate order ID to redirect to IPG
  generateOrder(accessToken, customerSlug, userToken) {

    return this.http.post<Response>(this.generateOrderURL, {
      slug: customerSlug,
      login_token:userToken
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        // this.response = resp;

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  // Tokenized pay for latest installment :have to pass order ID
  payNow(accessToken, orderRefNumber, userSlug, userToken) {

    return this.http.post<Response>(this.payNowURL, {
      oid: orderRefNumber,
      slug: userSlug,
      login_token: userToken
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        // this.response = resp;

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  // Remove tokenized card
  removeCard(accessToken, cardCode, customerSlug,  userToken) {

    return this.http.post<Response>(this.removeCardURL, {
      slug: customerSlug,
      card_code: cardCode,
      login_token: userToken
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        // this.response = resp;

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  // Update Default tokenized card
  updateDefaultCard(accessToken, cardCode, customerSlug, userToken) {

    return this.http.post<Response>(this.updateDefaultCardURL, {
      slug: customerSlug,
      card_code: cardCode,
      is_default: 1,
      login_token: userToken
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        // this.response = resp;

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }
}
