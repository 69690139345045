import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-failed',
  templateUrl: './card-failed.component.html',
  styleUrls: ['./card-failed.component.scss']
})
export class CardFailedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if (localStorage.getItem('displayName') != null) {
      $('.main').addClass('d-none');
      $('.profile').removeClass('d-none');
    }
  }

}
