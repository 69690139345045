const isProd = window.location.hostname === 'http://localhost:4200/' || window.location.hostname === 'www.payd.com';

export const environment = {
  production: true,
  apiURL: isProd ? 'https://paydread.com/' : 'http://apiwrite.retailbizz.io/',
  accessKeyId: 'AKIAWNHTX4QVTLXJ3Y3S',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
  baseURL: isProd ? 'https://customer.retailbizz.io' : 'https://customer.retailbizz.io'
};
